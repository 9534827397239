// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/AdminMenu.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/AdminMenu.tsx");
  import.meta.hot.lastModified = "1728334464000";
}
// REMIX HMR END

import { ADMIN_PAGES_URL } from '~/lib/constants';
import { MenuList } from './MenuList';
const customerAppMenu = [{
  name: 'Dashboard',
  url: ADMIN_PAGES_URL.ADMIN,
  permissionsRequired: [],
  children: [],
  end: true
}, {
  name: 'Workspace Management',
  url: ADMIN_PAGES_URL.WORKSPACE_MANAGEMENT.INDEX,
  permissionsRequired: ['admin.workspace.list', 'admin.workspace.create', 'admin.workspace.modules.create', 'admin.workspace.modules.list', 'admin.workspace.permissions.list', 'admin.workspace.permissions.create', 'admin.templates.brsr.create', 'admin.templates.brsr.update', 'admin.templates.brsr.list', 'admin.templates.brsr.delete', 'admin.templates.brsr.view'],
  children: [{
    name: 'Workspaces',
    url: ADMIN_PAGES_URL.WORKSPACE_MANAGEMENT.WORKSPACES_LIST,
    permissionsRequired: ['admin.workspace.list', 'admin.workspace.create'],
    children: [],
    end: false
  }, {
    name: 'Modules',
    url: ADMIN_PAGES_URL.WORKSPACE_MANAGEMENT.WORKSPACES_MODULES,
    permissionsRequired: ['admin.workspace.modules.create', 'admin.workspace.modules.list'],
    children: [],
    end: true
  }, {
    name: 'Module Permissions',
    url: ADMIN_PAGES_URL.WORKSPACE_MANAGEMENT.WORKSPACES_MODULES_PERMISSIONS,
    permissionsRequired: ['admin.workspace.modules.permissions.list', 'admin.workspace.modules.permissions.create'],
    children: [],
    end: true
  }, {
    name: 'Dynamic Report Templates',
    url: ADMIN_PAGES_URL.TEAMPLATE_MANAGEMENT.DYNAMIC_REPORT_LIST,
    permissionsRequired: ['admin.templates.brsr.create', 'admin.templates.brsr.update', 'admin.templates.brsr.list', 'admin.templates.brsr.delete', 'admin.templates.brsr.view'],
    children: [],
    end: true
  }],
  end: false
}, {
  name: 'User Management',
  url: ADMIN_PAGES_URL.USER_MANAGEMENT.INDEX,
  permissionsRequired: ['admin.user.list', 'admin.user.create', 'admin.user.roles.create', 'admin.user.roles.list', 'admin.user.permissions.list', 'admin.user.permissions.create'],
  children: [{
    name: 'Users',
    url: ADMIN_PAGES_URL.USER_MANAGEMENT.USER_LIST,
    permissionsRequired: ['admin.user.list', 'admin.user.create'],
    children: [],
    end: false
  }, {
    name: 'Roles',
    url: ADMIN_PAGES_URL.USER_MANAGEMENT.USER_ROLES,
    permissionsRequired: ['admin.user.roles.create', 'admin.user.roles.list'],
    children: [],
    end: false
  }, {
    name: 'Permissions',
    url: ADMIN_PAGES_URL.USER_MANAGEMENT.USER_PERMISSIONS,
    permissionsRequired: ['admin.user.permissions.list', 'admin.user.permissions.create'],
    children: [],
    end: false
  }],
  end: false
}, {
  name: 'Configuration',
  url: ADMIN_PAGES_URL.CONFIGURATION_MANAGEMENT.INDEX,
  permissionsRequired: [],
  children: [{
    name: 'Emission Calculation Configuration',
    url: ADMIN_PAGES_URL.CONFIGURATION_MANAGEMENT.CONFIGURATION_LIST,
    permissionsRequired: [],
    children: [],
    end: false
  }, {
    name: 'Maturity Configuration',
    url: ADMIN_PAGES_URL.CONFIGURATION_MANAGEMENTS.INDEX,
    permissionsRequired: [],
    children: [],
    end: false
  }, {
    name: 'Kpi Library',
    url: ADMIN_PAGES_URL.KPI_LIBRARY.INDEX,
    permissionsRequired: [],
    children: [],
    end: false
  }],
  end: false
}];
export const AdminMenu = ({
  user = null
}) => {
  return <div>
      <MenuList menu={customerAppMenu} />
    </div>;
};
_c = AdminMenu;
var _c;
$RefreshReg$(_c, "AdminMenu");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;