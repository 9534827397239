// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/EntitySwitcher.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/EntitySwitcher.tsx");
  import.meta.hot.lastModified = "1728334464000";
}
// REMIX HMR END

import { CaretSortIcon, CheckIcon } from '@radix-ui/react-icons';
import { cn, formatUrlParams } from '~/lib/utils';
import { Avatar, AvatarFallback } from '~/components/ui/avatar';
import { Button } from '~/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator } from '~/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '~/components/ui/popover';
import { Fragment, useMemo, useState } from 'react';
import { APP_PAGES_URL, ENTITY_TYPE, SYSTEM_USER_TYPES } from '~/lib/constants';
import { useWorkspace } from '~/components/Workspaces/WorkspaceContext';
import { useParams } from '@remix-run/react';
export const EntitySwitcher = ({
  className,
  user
}) => {
  _s();
  const {
    entitiesList = [],
    workspacesList = []
  } = useWorkspace();
  const {
    entityId,
    workspaceId
  } = useParams();
  const groupsList = entitiesList?.filter(entity => entity?.entityType === ENTITY_TYPE.OrganizationProfile)?.map(item => ({
    label: item?.name,
    value: item?.entityId,
    type: ENTITY_TYPE.Entity
  }));
  const entitiesItems = entitiesList?.filter(entity => entity?.entityType === ENTITY_TYPE.Entity)?.map(item => ({
    label: item?.name,
    value: item?.entityId,
    type: ENTITY_TYPE.Entity
  }));
  const otherWorkspaces = workspacesList?.filter(item => `${item?.code}`.toLowerCase() !== `${workspaceId}`.toLowerCase())?.map(item => ({
    label: item?.name,
    value: item?.code,
    type: ENTITY_TYPE.Workspace
  }));
  const currentSelectedValue = useMemo(() => {
    const currentSelectedValue = entitiesList?.find(entity => `${entity?.entityId}`.toLowerCase() === `${entityId}`.toLowerCase());
    return {
      label: currentSelectedValue?.name,
      value: currentSelectedValue?.entityId
    };
  }, [entityId, entitiesList]);
  const entitiesOptions = [];
  if (groupsList?.length > 0) {
    entitiesOptions.push({
      label: 'Groups',
      entities: groupsList
    });
  }
  if (entitiesItems?.length > 0) {
    entitiesOptions.push({
      label: 'Entities',
      entities: entitiesItems
    });
  }
  if (otherWorkspaces?.length > 0) {
    entitiesOptions.push({
      label: 'Switch Workspace',
      entities: otherWorkspaces
    });
  }
  const [open, setOpen] = useState(false);
  const onEntityChange = entity => {
    if (entity.type === ENTITY_TYPE.Workspace) {
      window.location.href = formatUrlParams(APP_PAGES_URL.WORKSPACE.INDEX, {
        workspaceId: entity?.value
      });
      return;
    }
    const currentUrl = window.location.href;
    const newEntityUrl = currentUrl.replace(/\/page\/([^/]*)/, `/page/${entity?.value}`);
    window.location.href = newEntityUrl;
  };
  if (user?.type !== SYSTEM_USER_TYPES.FE_USER || !entityId || !workspaceId || !entitiesList?.length) {
    return <Fragment />;
  }
  return <div>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button variant='outline' role='combobox' aria-expanded={open} aria-label='Select a team' className={cn('w-[200px] justify-between', className)}>
            <Avatar className='mr-2 h-6 w-6'>
              {currentSelectedValue?.label ? <AvatarFallback className='uppercase text-xs bg-primary-100 border border-primary-300'>{`${currentSelectedValue?.label?.[0]}${currentSelectedValue?.label?.[1]}`}</AvatarFallback> : ''}
            </Avatar>
            <span className='truncate'>
              {currentSelectedValue?.label || 'Select an Entity'}
            </span>
            <CaretSortIcon className='ml-auto h-4 w-4 shrink-0 opacity-50' />
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-[200px] p-0'>
          <Command>
            <CommandList>
              <CommandInput placeholder='Search entity...' />
              <CommandEmpty>No entity found.</CommandEmpty>
              {entitiesOptions.map(group => <CommandGroup key={group.label} heading={group.label}>
                  {group.entities.map(entity => <CommandItem key={entity.value} disabled={entity.value === currentSelectedValue?.value} onSelect={() => {
                onEntityChange(entity);
              }} className={cn('text-sm cursor-pointer', currentSelectedValue?.value === entity.value ? 'bg-primary-50' : '!bg-white hover:!bg-primary-100')}>
                      <Avatar className='mr-2 h-6 w-6'>
                        <AvatarFallback className='uppercase text-xs bg-primary-100 border border-primary-300'>{`${entity.label?.[0]}${entity.label?.[1]}`}</AvatarFallback>
                      </Avatar>
                      {entity.label}
                      <CheckIcon className={cn('ml-auto h-4 w-4', currentSelectedValue?.value === entity.value ? 'opacity-100' : 'opacity-0')} />
                    </CommandItem>)}
                </CommandGroup>)}
            </CommandList>
            <CommandSeparator />
          </Command>
        </PopoverContent>
      </Popover>
    </div>;
};
_s(EntitySwitcher, "Bh7vBDA4sBpJSTtbU2XGtFueLJE=", false, function () {
  return [useWorkspace, useParams];
});
_c = EntitySwitcher;
var _c;
$RefreshReg$(_c, "EntitySwitcher");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;