// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/MenuList/ChildMenu.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/MenuList/ChildMenu.tsx");
  import.meta.hot.lastModified = "1728334464000";
}
// REMIX HMR END

import { NavLink } from '@remix-run/react';
import { ChevronDownIcon } from 'lucide-react';
import { useState } from 'react';
import { cn } from '~/lib/utils';
import { RestrictedElement } from '~/components/RestrictedElement';
export const ChildMenu = ({
  menu
}) => {
  _s();
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = e => {
    e.preventDefault();
    setIsOpen(val => !val);
    return false;
  };
  return <>
      <NavLink reloadDocument to={menu?.url} className={({
      isActive
    }) => cn('text-sm hover:text-primary-800 duration-150 transition-all hover:bg-primary-50 px-4 rounded-md md:py-3 py-4 border-b md:border-none flex flex-nowrap justify-between items-center align-middle', isActive ? 'bg-primary-50' : '')} onClick={toggleMenu}>
        <span>{menu?.name}</span>
        <span>
          <ChevronDownIcon size={16} className={cn('text-primary-800 duration-300 transition-all', isOpen ? 'rotate-180' : '')} />
        </span>
      </NavLink>
      <ul className={cn('mt-2 space-y-1 px-4', isOpen ? 'block' : 'hidden')}>
        {menu?.children?.map((child, childIdx) => {
        return <RestrictedElement permissions={child?.permissionsRequired} key={`${childIdx}-${child.name}`}>
              <li className='relative py-0.5 border-primary-50 border-b first-of-type:border-t last-of-type:border-b-0'>
                {child?.children?.length > 0 ? <ChildMenu menu={child} key={`${childIdx}-${child.name}`} /> : <NavLink reloadDocument to={child.url} className={({
              isActive
            }) => cn('text-sm hover:text-primary-800 duration-150 transition-all hover:bg-primary-50 px-4 rounded-md md:py-3 py-4 block border-b md:border-none text-left', isActive ? 'bg-primary-50' : '')} end={child?.end}>
                    {child.name}
                  </NavLink>}
              </li>
            </RestrictedElement>;
      })}
      </ul>
    </>;
};
_s(ChildMenu, "+sus0Lb0ewKHdwiUhiTAJFoFyQ0=");
_c = ChildMenu;
var _c;
$RefreshReg$(_c, "ChildMenu");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;