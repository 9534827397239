// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/Header.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/Header.tsx");
  import.meta.hot.lastModified = "1728334464000";
}
// REMIX HMR END

import { useNavigation } from '@remix-run/react';
import { AnchorLink } from '~/components/AnchorLink';
import { Button } from '~/components/ui/button';
import { AUTH_PAGES_URL } from '~/lib/constants';
import { useState } from 'react';
import { MenuIcon } from 'lucide-react';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '~/components/ui/sheet';
import { AppMenu } from './AppMenu';
import { useAuth } from '~/components/Auth/AuthContext';
import { UserMenu } from './UserMenu';
import { EntitySwitcher } from './EntitySwitcher';
import { WorkflowSwitcher } from './WorkflowSwitcher';
export const Header = () => {
  _s();
  const {
    state
  } = useNavigation();
  const {
    isLoggedIn,
    user = null
  } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(val => !val);

  // useEffect(() => {
  //   if (isMenuOpen && state === 'loading') setIsMenuOpen(!isMenuOpen);
  // }, [state, isMenuOpen]);

  return <>
      <header className='bg-white/80 sticky top-0 backdrop-blur-md z-50 shadow-sm'>
        <div className='mx-auto flex container items-center gap-4 md:gap-8 px-4 justify-between w-full relative h-[4.5rem]'>
          <div className='flex flex-wrap justify-start align-middle items-center shrink-0'>
            <Button variant='ghost' onClick={toggleMenu} className='p-2'>
              <MenuIcon size={24} />
            </Button>
            <div className='px-3 hidden md:block'>
              {isLoggedIn ? <EntitySwitcher user={user} /> : ''}
            </div>
          </div>
          <div className='w-24 md:w-32  inline-flex top-0 left-0 right-0 mx-auto'>
            <AnchorLink className='block text-primary-500 py-2' to='/'>
              <img src='/assets/newlogo.png' alt='SAM Corporate' className='w-24 h-14 md:w-32 mx-auto' />
            </AnchorLink>
          </div>
          <div className='flex justify-end align-middle items-center shrink-0'>
            {isLoggedIn ? <div className='relative flex justify-center align-middle items-center space-x-4'>
                <WorkflowSwitcher user={user} />
                <UserMenu user={user} />
              </div> : <>
                <Button asChild>
                  <AnchorLink reloadDocument to={AUTH_PAGES_URL.LOGIN}>
                    Login
                  </AnchorLink>
                </Button>
              </>}
          </div>
        </div>
      </header>

      <Sheet open={isMenuOpen} onOpenChange={toggleMenu}>
        <SheetContent className='w-80 md:w-full overflow-y-auto' side='left'>
          <SheetHeader>
            <SheetTitle>
              <img src='/assets/newlogo.png' alt='SAM Corporate' className='w-20 md:w-24 h-auto mx-auto' />
            </SheetTitle>
            <>
              {isLoggedIn && <div className='block md:hidden w-full py-2'>
                  <EntitySwitcher user={user} />
                </div>}
              <AppMenu user={user} isLoggedIn={isLoggedIn} />
            </>
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </>;
};
_s(Header, "mJPJ7in2Jacb2z/4fUy/BZffEvw=", false, function () {
  return [useNavigation, useAuth];
});
_c = Header;
var _c;
$RefreshReg$(_c, "Header");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;