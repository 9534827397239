// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/UserMenu.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/UserMenu.tsx");
  import.meta.hot.lastModified = "1728334464000";
}
// REMIX HMR END

import { Avatar, AvatarFallback } from '~/components/ui/avatar';
import { Button } from '~/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '~/components/ui/dropdown-menu';
import { AnchorLink } from '../AnchorLink';
import { AUTH_PAGES_URL } from '~/lib/constants';
export const UserMenu = ({
  user
}) => {
  const nameShort = `${user?.profile?.firstname?.[0]}${user?.profile?.lastname?.[0]}`;
  return <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant='ghost' className='relative h-8 w-8 rounded-full'>
          <Avatar className='h-10 w-10'>
            <AvatarFallback className='bg-primary-100 border border-primary-300'>
              {nameShort}
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='w-56' align='end' forceMount>
        <DropdownMenuLabel className='font-normal'>
          <div className='flex flex-col space-y-1'>
            <span className='text-sm font-medium leading-none overflow-hidden'>
              {`${user?.profile?.firstname} ${user?.profile?.lastname}`.toLowerCase()}
            </span>
            <span className='text-xs leading-none text-muted-foreground overflow-hidden'>
              {`${user?.email}`.toLowerCase()}
            </span>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem className='hover:!bg-primary-100'>
            Settings
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem className='hover:!bg-primary-100'>
          <AnchorLink reloadDocument to={AUTH_PAGES_URL.LOGOUT} className={'block w-full'}>
            Logout
          </AnchorLink>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>;
};
_c = UserMenu;
var _c;
$RefreshReg$(_c, "UserMenu");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;