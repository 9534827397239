// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Footer/Footer.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Footer/Footer.tsx");
  import.meta.hot.lastModified = "1728334464000";
}
// REMIX HMR END

import pckg from '../../../package.json';
export const Footer = () => {
  const date = new Date();
  const version = pckg?.version || '1.0.0';
  return <footer className='bg-gray-50 p-3 flex w-full justify-center items-center align-middle'>
      <p className='text-sm text-gray-600'>
        &copy; Copyright {date.getFullYear()} SAM Corporate. All rights
        reserved.
        <span className='ml-2 text-slate-400 text-xs'>Build v{version}</span>
      </p>
    </footer>;
};
_c = Footer;
export const LandingFooter = () => {
  const date = new Date();
  const version = pckg?.version || '1.0.0';
  return <footer className=' bg-[#00293A] p-3 flex w-full justify-center items-center align-middle'>
      <p className='text-sm text-white/50'>
        &copy; Copyright {date.getFullYear()} SAM Corporate. All rights
        reserved.
        <span className='ml-2 text-slate-400 text-xs'>Build v{version}</span>
      </p>
    </footer>;
};
_c2 = LandingFooter;
var _c, _c2;
$RefreshReg$(_c, "Footer");
$RefreshReg$(_c2, "LandingFooter");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;